export const openPositions = [

    {
        url: "ceo",
        role: "Chief Executive Officer",
        location: "Gurugram, Haryana, India",
        jobDescription: "We are seeking a visionary and driven Chief Executive Officer (CEO) to lead CUT into its next phase of growth and impact. As CEO, you will be the driving force behind our mission, setting the strategic vision, building a high-performing team, and leading the company towards achieving its ambitious goals. You will be instrumental in defining CUT's direction, forging strategic partnerships, and ensuring the company's financial sustainability.",
        responsibilities: [
            {
                heading: "Strategic Leadership",
                subPoints: [
                    "Develop and execute CUT's long-term strategic vision, ensuring alignment with market trends and stakeholder interests.",
                    "Identify new market opportunities and drive expansion into adjacent areas.",
                    "Foster a culture of innovation and continuous improvement throughout the organization."
                ]
            },
            {
                heading: "Operational Excellence",
                subPoints: [
                    "Oversee all aspects of CUT's operations, including product development, marketing, sales, customer success, and finance.",
                    "Build and manage a high-performing leadership team, providing mentorship and guidance to ensure organizational effectiveness.",
                    "Implement robust processes and systems to drive efficiency, scalability, and profitability."
                ]
            },
            {
                heading: "Financial Performance",
                subPoints: [
                    "Drive revenue growth through effective pricing strategies, sales execution, and customer acquisition.",
                    "Secure funding and manage investor relations to support CUT's growth trajectory.",
                    "Ensure financial transparency and accountability across the organization."
                ]
            },
            {
                heading: "External Relations",
                subPoints: [
                    "Build and maintain strong relationships with key stakeholders, including customers, investors, partners, regulators, and industry influencers.",
                    "Represent CUT in industry forums and events, advocating for the company's mission and values.",
                    "Foster strategic partnerships and collaborations to expand CUT's reach and impact."
                ]
            }
        ],
        qualifications: [
            {
                heading: "Proven Leadership",
                description: "Demonstrated success in leading and scaling high-growth technology companies, preferably in the cleantech or sustainability sectors."
            },
            {
                heading: "Strategic Vision",
                description: "Ability to develop and articulate a compelling vision for the future of CUT, inspiring others to join the journey."
            },
            {
                heading: "Business Acumen",
                description: "Strong understanding of business operations, financial management and strategic planning."
            },
            {
                heading: "Technology Expertise",
                description: "Deep understanding of technology trends, product development, and the carbon management landscape."
            },
            {
                heading: "Fundraising Experience",
                description: "Proven ability to raise capital from investors and manage investor relationships."
            },
            {
                heading: "Exceptional Communication and Interpersonal Skills",
                description: "Ability to inspire and motivate teams, build consensus, and effectively communicate with diverse stakeholders."
            },
        ],
        additionalInformation: [
            {
                heading: "Location",
                description: "Open to remote candidates, with a preference for those who can be based in Gurgaon, India."
            },
            {
                heading: "Compensation",
                description: "Competitive salary and ESOP/equity package, commensurate with experience and qualifications."
            },
            {
                heading: "Reporting",
                description: "The CEO will be part of the core Founders team and report to the Board of Directors."
            }
        ]
    },


    {
        url: "cto",
        role: "Chief Product & Technology Officer",
        location: "Gurugram, Haryana, India",
        jobDescription: "We are seeking a visionary and accomplished Chief Product & Technology Officer (CPTO) to join our leadership team and play a pivotal role in shaping the future of CUT. As CPTO, you will be responsible for leading the conceptualization, development, and execution of our SaaS product and technology roadmap. You will be instrumental in defining our product vision, overseeing the entire product lifecycle, and building a high-performing product and engineering team.",
        responsibilities: [
            "Lead the product strategy and roadmap, ensuring alignment with CUT's overall business goals and market needs.",
            "Oversee all aspects of product development, from ideation and user research to design, development, and launch.",
            "Manage and mentor the product and engineering teams, fostering a culture of innovation and excellence.",
            "Drive the development and implementation of a robust technology infrastructure to support our products and growth.",
            "Collaborate closely with other departments, including marketing, sales, and customer success, to ensure a seamless user experience.",
            "Stay abreast of the latest trends and technologies in the carbon management and climate tech space.",
        ],
        qualifications: [
            "Proven track record of success in leading product and technology teams in a high-growth environment with experience of over 10 years.",
            "Strong product vision and strategic thinking capabilities.",
            "Experience in building and scaling SaaS technology products from concept to launch.",
            "Excellent communication, collaboration, and leadership skills.",
            "A passion for sustainability and a desire to make a positive impact on the planet.",
            "Understanding of the carbon management and climate tech landscape would be of added advantage.",
        ],
        additionalInformation: [
            {
                heading: "Location",
                description: "Open to remote candidates, with a preference for those who can be based in Gurgaon, India."
            },
            {
                heading: "Compensation",
                description: "Competitive salary and ESOP/equity package, commensurate with experience and qualifications."
            },
            {
                heading: "Potential",
                description: "the ideal candidate will have the potential to be elevated to the position of Co-Founder of CUT."
            },
            {
                heading: "Reporting",
                description: "The CPTO will be part of the core Founders team and report to the CEO."
            }
        ]
    },

    {
        url: "unsolicited",
        role: "Unsolicited Application",
        location: "Gurugram, Haryana, India",
        jobDescription: "Didn't find a role that fits you?",
    }

]