export const partnersData = [
    {
        id: "1",
        name: "Amplo Global",
        intro: "Amplo Global Inc., a pioneering AI company empowering organizations worldwide.",
        desc: "Carbon U-Turn Technology Pvt. Ltd. (CUT)  and Amplo Global Inc. (AGI) have forged a collaborative relationship to develop a unique platform approach. This approach enables organizations to effectively address sustainability risks, paint scenarios, simulate strategies, track actions, and provide tailored business solutions. We believe that this solution will revolutionize the way businesses approach and achieve their sustainability objectives.",
        img: "/Images/Partners/amplo.png",
        website: "https://amploglobal.com/",
        linkedin: "www.linkedin.com/company/amploglobal/",
    },
    {
        id: "2",
        name: "Indian Institute of Technology Roorkee",
        intro: "IIT Roorkee, a prestigious institution excelling in technology education and research.",
        desc: "Carbon U-Turn Technology Pvt. Ltd. (CUT) and IIT Roorkee (IITR) have collaborated to develop e-learning modules aimed at creating awareness of climate change and contributing to the Net Zero mission. IITR and CUT will engage in mutually agreed collaborative research, leveraging each other's expertise and facilities as necessary.",
        img: "/Images/Partners/iitr.png",
        website: "https://www.iitr.ac.in/",
        linkedin:
            "www.linkedin.com/school/indian-institute-of-technology-roorkee/",
    },
    {
        id: "3",
        img: "",
    },
    {
        id: "4",
        img: "",
    },
    {
        id: "5",
        img: "",
    },
    {
        id: "6",
        img: "",
    },
    {
        id: "7",
        img: "",
    },
    {
        id: "8",
        img: "",
    },
    {
        id: "9",
        img: "",
    },
]
