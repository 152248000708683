import "./servicesHero.css"

export default function Hero() {
    return (
        <section className="services-hero">
            <div className="content" data-aos="fade-up">
                <h1>Our Services</h1>
                <hr />
                <p>
                    We provide to <span>serve the planet</span>
                </p>
            </div>
        </section>
    )
}
