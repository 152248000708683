import React from 'react'
import "./modules.css"
const Modules = () => {
  return (
    <div className='modules'>
        <div className='modules-title'>MODULES</div>
        <div className='modules-subtitle'>COMING SOON......</div>
    </div>
  )
}

export default Modules