export const data = [
    {
        id: "01",
        head: "Carbon Credit Marketplace",
        smallContent: "Fight climate change, minimize your footprint.",
        content:
            "Fight climate change, minimize your footprint. Carbon Uturn connects you to impactful projects that reduce emissions or remove carbon from the atmosphere. Invest directly and support initiatives making a real difference.  Every credit counts. Our secure platform simplifies finding projects aligned with your sustainability goals.",
        img: "/Images/ServicesCards/s1.png",
    },
    {
        id: "02",
        head: "Building Awareness",
        smallContent: "Empower your team for a sustainable future.",
        content:
            "Empower your team for a sustainable future. Carbon Uturn's E-learning modules make climate change, carbon footprinting, and responsible business practices clear and engaging. Accessible and interactive, these modules build employee awareness and equip them to make informed decisions that contribute to your sustainability goals.",
        img: "/Images/ServicesCards/s2.png",
    },
    {
        id: "03",
        head: "Carbon Accounting & Dashboard",
        smallContent: "Navigate your sustainability journey with us",
        content:
            "Our expert advisory services provide the guidance and data you need to navigate your sustainability journey. Our team of experienced professionals offers comprehensive assessments and personalized recommendations to help you achieve measurable results. We becomes your trusted partner, providing a clear roadmap to achieving your sustainability goals with confidence.",
        img: "/Images/ServicesCards/s3.png",
    },
    {
        id: "04",
        head: "Enabled Registration",
        smallContent: "To help you meet necessary environmental standards",
        content:
            " Carbon Uturn ensures that your project meets the necessary standards and maximizes its environmental impact. We provide expert guidance and assist you with completing the registration process accurately and efficiently. Our team helps you navigate the requirements. This allows you to contribute to the global carbon market and create a positive environmental impact.",
        img: "/Images/ServicesCards/s4.png",
    },
]
