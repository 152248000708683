import React from "react"
import "./services.css"
import Navbar from "../../Components/Navbar/Navbar"
import Hero from "../../Components/Services/Hero"
import CardsSection from "../../Components/Services/Cards/CardsSection"
import Footer from "../../Components/Footer/Footer"

const Services = () => {
    return (
        <>
            <Navbar />
            <Hero />
            <CardsSection />
            <Footer />
        </>
    )
}

export default Services
